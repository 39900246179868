.modal-container {
  border-radius: 32px 32px 0px 0px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 0px;
  transition: .3s;
  .modal-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0px 16px;
  }
}


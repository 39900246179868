.nav-container {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  border-bottom: 1px solid var(--Grewy-200, #eaecf0);
  .app-logo-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    .app-logo {
      width: 18px;
      height: 18px;
    }
  }
}

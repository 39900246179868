.range-class{
  z-index: 0;
  margin: 16px 0px;
  .range-slider__thumb{
    background:#000000;
    height: 16px;
    width: 16px;
  }
  .range-slider__range {
    background-color: #000000;
  }
}
.home-contianer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  // padding: 16px;
  .home-sub-container {
    padding: 20px 8px;
  }
  .top-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    gap: 16px;
    .header-title {
      color: var(--900, #101828);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .icon-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .gender-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .bot-message {
    color: var(--Grey-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.142px;
    padding: 16px;
  }
  .message-products {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    height: 100%;
    width: -webkit-fill-available;
    padding: 16px;

    .message-title {
      color: #101828;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
    .products-list-category-home {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 8px;
    }
    .static-content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      .main-header {
        position: relative;
        color: var(--900, #101828);
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        padding: 16px 0px;
        line-height: 40px;
        img {
          position: absolute;
          height: 40px;
          width: 40px;
        }
      }
      .static-messages-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }
      .static-message {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        border: 1px solid var(--200, #eaecf0);
        background: rgba(255, 255, 255, 0.08);
        text-align: center;
        color: var(--900, #101828);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.gender-toggle-container {
  display: flex;
  padding: 4px 6px 4px 4px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid var(--200, #eaecf0);
  background: var(--Chat, #fbf9f1);
  width: 100%;
  .gender-contanier {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 8px 24px;
  }
  .selected-gender {
    border-radius: 50px;
    border: 1px solid var(--200, #eaecf0);
    background: var(--White, #fff);
  }

  .coose-gender {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 8px 24px;
  }
  .gender-drop-down {
    width: 56%;
    display: flex;
    justify-content: flex-start;
    padding: 8px 12px;
    align-items: center;
    gap: 16px;
    border-radius: 50px;
    border: 1px solid var(--200, #eaecf0);
    background: var(--White, #fff);
    text-transform: capitalize;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.gender-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 300px;
  overflow: auto;
  height: 100%;
  .gender-modal-header {
    color: #101828;
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
  }
  .gender-modal-sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 32px 0px;
    .gender-sub {
      display: flex;
      align-items: center;
      gap: 24px;
      .gender {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .selected-gender-img {
    border: 4px solid blue;
    border-radius: 100%;
  }
  .next-btn {
    border-radius: 100px;
    opacity: 0.5;
    background: #101828;
    color: #fff;
    padding: 10px 18px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
}

.sugg-modal-container {
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .modal-main-header {
    color: #101828;
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-transform: capitalize;
  }
  .sugg-options-container {
    height: 26vh;
    overflow: scroll;
  }
  .sugg-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0px;
    color: #344054;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
  }
  .save-proceed {
    border-radius: 100px;
    background: #101828;
    display: flex;
    height: 54px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
  }
}

.modal-visible {
  transform: translate(0, 40%);
  transition: all 500ms ease-in-out;
  height: 100%;
}

.modal-content {
  padding: 0px 16px;
}
.min-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.white-btn {
  border-radius: 100px;
  border: 1px solid var(--200, #eaecf0);
  background: var(--White, #fff);
  padding: 12px 32px;
  cursor: pointer;
  width: 100%;
}
.black-btn-apply {
  border-radius: 100px;
  border: 1px solid var(--200, #eaecf0);
  background: var(--White, #000);
  color: #fff;
  padding: 12px 32px;
  cursor: pointer;
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
.price-filter-container {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--200, #eaecf0);
  width: 100%;
}

.carousel-slider .slide.selected {
  width: 100% !important;
}

.title-header {
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: Inter;
  font-style: normal;
}
*::-webkit-scrollbar {
  display: none !important;
}

* {
  -webkit-tap-highlight-color: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.loader-component {
  width: 100%;
  .loader-div {
    border: 1px;
    height: 50px;
    margin: 14px 0;
    border-radius: 8px;
    background: linear-gradient(
      to right,
      #ffffff 20%,
      rgb(78, 78, 78) 50%,
      #fff 80%
    );
    background-size: 100% 50px;
    animation-name: moving-gradient;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0.2;
  }

  .card {
    width: 45%;
    height: 208px;
    margin: 16px auto;
    background-color: #ffffff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);

    background: linear-gradient(
      to right,
      #ffffff 20%,
      rgb(78, 78, 78) 50%,
      #fff 80%
    );
    background-size: 100% 50px;
    animation-name: moving-gradient;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0.2;
  }

  @keyframes moving-gradient {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 500px 0;
    }
  }
}

.btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
  overflow: auto;
  padding: 0px 12px;
  .white-btn-list {
    border-radius: 100px;
    border: 1px solid var(--200, #eaecf0);
    background: var(--White, #fff);
    padding: 10px 20px;
    cursor: pointer;
    width: max-content;
    white-space: nowrap;
    color: var(--900, #101828);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }
  .black-btn {
    border-radius: 100px;
    border: 1px solid var(--200, #eaecf0);
    background: var(--White, #000000);
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }
}

.recommand-contianer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 16px;
  position: sticky;
  top:0;
  .prod-back-container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0px;
    .product-name {
      color: #1d1b20;
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .product-price {
      color: #1d1b20;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.142px;
    }
    .product-thumbnail {
      height: 40px;
      width: 40px;
    }
  }
  .recommand-title {
    color: var(--900, #101828);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
}

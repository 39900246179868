video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

.video-container {
  height: 94vh;
  width: 100vw;
  position: relative;
  background-color: rgba(70, 70, 70, 0.368627451);
  display: flex;
  flex-direction: column;
  align-items: center;
  .video {
    height: 100%;
    width: 100%;
  }
  .video-footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    justify-content: space-between;
    padding: 0px 32px;
    cursor: pointer;
    .capture-btn {
      background-color: #ffffffce;
      height: 80px;
      width: 80px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      .capture-btn-inner {
        height: 60px;
        width: 60px;
        background-color: #fff;
        border-radius: 50%;
        margin: 2px;
        position: absolute;
      }
    }
    .attach-icon-container{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #56565773;
      padding: 16px 14px;
      border-radius: 50%;
    }
  }
}
.image-main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 94vh;
  width: 100%;
  gap: 24px;
  padding: 16px;
  .capturred-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    color: #101828;
    text-align: center;
    font-family: Roboto;
    margin-top: 32px;

    .captured-header {
      font-size: 24px;
      font-weight: 500;
    }
    .captured-footer {
      font-weight: 400;
      font-size: 12px;
    }
  }
  .image-container {
    height: 421px;
    border-radius: 8px;
    .captured-image {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .btns-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    .use-img-btn {
      color: #fff;
      display: flex;
      height: 54px;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: #101828;
      width: 100%;
      cursor: pointer;
    }
    .try-again-btn {
      display: flex;
      height: 54px;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      width: 100%;
      border: 1px solid #ece6f0;
      cursor: pointer;
    }
  }
}

.category-message-contianer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 16px;
  .main-header {
    color: var(--900, #101828);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    padding: 16px 0px;
  }
  .budget {
    margin: 16px 0px;
  }

  .price-range-container {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  .products-list-home {
    height: 60vh;
    // margin-top: 24px;
    overflow: auto;
    // padding: 100px 0px;
  }
  .products-list-category-home {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }
}

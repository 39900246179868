.logout-main {
  padding: 0px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .log-out-container {
    display: flex;
    flex-direction: column;
    .titles {
      display: flex;
      padding: 16px 0px;
      gap: 16px;
      align-items: center;
      color: #101828;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .modal-nav {
    display: flex;
    justify-content: space-between;
  }
}

.modal-visible-plus-50 {
  transform: translate(0, 68%);
  transition: all 500ms ease-in-out;
}

.footer-container {
  position: fixed;
  bottom: 0;
  // display: flex;
  z-index: 1;
  left: 0;
  padding: 8px 16px;
  gap: 8px;
  border-top: 1px solid var(--200, #eaecf0);
  background-color: #ffffff;
  width: 100%;
  .mic-keypad {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    gap: 72px;
  }
  .mic-container {
    // position: absolute;
    // right: 40%;
    // margin: auto;
  }
  .keypad-container {
    // position: absolute;
    // right: 0;
  }
  .input-container {
    border-radius: 28px;
    background: #fbf9f1;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    margin: 8px 16px;
    min-height: 56px;
    max-height: 100px;
    justify-content: center;
    .streamoid-user-input {
      background-color: #fbf9f1;
      color: #49454f;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.5px;

      width: 90%;
      padding: 4px 8px 4px 8px;
      border-radius: 12px;
      outline: none;
      border: none;
      /* resize: none; */
      overflow-y: auto;
      max-height: 100px;
      cursor: text;
    }
  }

  .sugg-data-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    border: 1px solid rgba(234, 236, 240, 0.5);
    background: #fbf9f1;
    height: 40px;
    padding: 10px 4px 10px 16px;
    gap: 16px;
    .sugg-option-container {
      display: flex;
      padding: 8px 6px 8px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 16px;
      background: #101828;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
    input {
      border: none;
      outline: none;
      background: transparent;
    }
  }
}

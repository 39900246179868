.products-list-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 180px;
  height: 68vh;
  overflow: auto;
  .product-container {
    width: 47%;
    border-radius: 7.819px;
    border: 1.069px solid #fff;
    .product-img-container {
      border-radius: 7.819px;
      border: 1.069px solid #fff;
      background-color: color(rec2020 0.93 0.93 0.93);
      .product-image {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 3/4;
        border-radius: 7.819px;
      }
    }
    .product-price {
      color: var(--900, #1d1b20);
      font-size: 11.728px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.152px;
    }
    .product-name {
      color: var(--900, #1d1b20);
      font-size: 11.728px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.152px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.product-window-contianer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 16px;
}

.rec-section {
  padding: 0px 0px 150px 0px;
  width: 100%;
  .prod-back-container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0px;
    .product-name {
      color: #1d1b20;
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .product-price {
      color: #1d1b20;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.142px;
    }
  }
  .selected-prod-img-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    .product-img-container {
      width: 100%;
      height: 358px;
      .product-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 3/4;
        border-radius: 7.819px;
      }
    }
    .buy-now {
      border-radius: 100px;
      background: #101828;
      display: flex;
      height: 48px;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      color: #fff;
      width: 100%;
    }
  }

  .products-horiz-container {
    display: flex;
    overflow: hidden;
    .similar-prod-container {
      .product-name {
        color: #1d1b20;
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .product-price {
        color: #7650af;
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .prod-img-container {
        // padding: 8px;
        width: 132px;
        height: 132px;
        .prod-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3/4;
          border-radius: 7.819px;
        }
      }
    }
  }

  .similar-complelentary {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    width: 100%;
    overflow: auto;
  }
  .similar-complelentary-sub {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .sub-headers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .arrow-container {
        border-radius: 9999px;
        background: #eaecf0;
        width: 20px;
        height: 20px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .buy-now-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 16px;
    font-family: Roboto;

    .take-home-header {
      color: #1d1b20;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.31px;
    }
    .take-home-desc {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.15px;
    }
    .go-back-btn {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: #101828;
      width: 100%;
      height: 48px;
      color: #fff;
    }
  }
}

.prod-spec-ans {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  .prod-spec-qus {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .ans {
    font-size: 16px;
    font-weight: 400;
  }
}

.pdp-loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251 251 251 / 45%);
  z-index: 10;
  top: -129px;
}

.categories-products {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  gap: 16px;
  padding: 24px 0px 100px 0px;
  overflow-y: auto;
  // height: 64vh;
  width: 100%;
  position: relative;

  .card-stack-1 {
    margin: auto;
    background-color: #cbcbcd;
    position: absolute;
    top: -6px;
    z-index: 1;
    left: 6px;
    height: 6px;
    display: flex;
    justify-content: center;
    width: calc(100% - 12px);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .card-stack-2 {
    margin: auto;
    background-color: #cbcbcd;
    position: absolute;
    top: -14px;
    z-index: 1;
    left: 12px;
    height: 6px;
    display: flex;
    justify-content: center;
    width: calc(100% - 24px);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .category {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
    .category-img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 0.9;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .category-container {
    border-radius: 8.55px 8.55px 0px 0px;
    border: 1.069px solid #fff;
    // width: 35vw;
    height: 181.688px;
    background-color: rgb(242, 241, 240);
    padding: 8px;
  }
  .category-title {
    display: flex;
    padding: 8.55px 17.1px;
    justify-content: center;
    align-items: center;
    gap: 8.55px;
    align-self: stretch;
    font-size: 14.963px;
    font-weight: 500;
    color: #fff;
    background-color: #101828;
    border-radius: 0px 0px 8px 8px;
    text-transform: capitalize;
  }
}
